import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faThList,
  faBorderAll,
  faHammer,
  faHeart,
  faEye,
  faShoppingBasket,
  faCheck,
  faShoppingCart,
  faTimes,
  faUser,
  faBars,
  faChevronDown,
  faGavel,
  faClock,
  faBatteryFull,
  faPhone,
  faStopwatch,
  faExclamationCircle,
  faWindowMaximize,
  faArrowRight,
  faMapMarker,
  faStar,
  faTruck,
  faMapMarkerAlt,
  faEnvelope,
  faAngleDoubleRight,
  faLongArrowAltRight,
  faTabletAlt,
  faTv,
  faAdjust,
  faBook,
  faAngleDown,
  faCogs,
  faEnvelopeOpenText,
  faAlignLeft,
  faTablet,
  faDesktop,
  faLaptop,
  faGamepad,
  faSpa,
  faMobile
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faSearch,
  faThList,
  faBorderAll,
  faHammer,
  faHeart,
  faEye,
  faShoppingBasket,
  faCheck,
  faShoppingCart,
  faTimes,
  faUser,
  faBars,
  faChevronDown,
  faGavel,
  faClock,
  faBatteryFull,
  faPhone,
  faStopwatch,
  faExclamationCircle,
  faWindowMaximize,
  faArrowRight,
  faMapMarker,
  faStar,
  faTruck,
  faMapMarkerAlt,
  faEnvelope,
  faAngleDoubleRight,
  faLongArrowAltRight,
  faTabletAlt,
  faTv,
  faAdjust,
  faBook,
  faAngleDown,
  faCogs,
  faEnvelopeOpenText,
  faAlignLeft,
  faTablet,
  faDesktop,
  faLaptop,
  faGamepad,
  faSpa,
  faMobile
);
