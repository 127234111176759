import carsApi from '../api/cars';
import router from '@/router/index'
var _ = require('lodash');
import axios from "axios";

export const cars = {
  state: () => ({
    appLoading: false,
    makers: [],
    cars: [],
    carListForShopPage: [],
    selectedCar: {},
  }),
  mutations: {
    SET_MAKERS(state, payload) {
      state.makers = payload;
    },
    SET_LOADING(state, payload) {
      state.appLoading = payload;
    },
    SET_SHOP_PAGE_CARS(state, payload) {
      state.carListForShopPage = payload;
    },
    SET_SELECTED_CAR(state, payload) {
      state.selectedCar = payload;
    },
    SET_CARS(state, payload) {
      // state.cars = payload;
      console.log(payload)
      state.cars.push(payload);
      let cars = _.orderBy(state.cars,['time'], ['desc'])
      state.cars = _.unionBy(cars, '_id');
    }
  },
  actions: {
    async getMakers(context) {
      // console.log("payload",payload);
      let res = await carsApi.getMakers()
      if (res) {
        console.log(res.data.data);
        let makers = res.data.data;
        makers.forEach(maker => {
          context.dispatch('getCars', maker);
        });
        context.commit('SET_MAKERS', res.data.data);
      }
    },
    setLoading(context, payload) {
      if (payload === false) {
        setTimeout(() => {
          context.commit('SET_LOADING', payload)
        }, 500);
      } else {
        context.commit('SET_LOADING', payload)
      }
    },
    async submitBid(context, payload) {
      // console.log("payload",payload);
      let res = await carsApi.submitBid(payload)
      if (res) {
        console.log(res.data.data);
      }
    },
    async selectCar(context, payload) {
      context.commit('SET_SELECTED_CAR', payload)
      // router.push({
      //   name: 'Checkout'
      // })
    },
    async setShopPageCars(context, payload) {
      context.commit('SET_SHOP_PAGE_CARS', payload)
      // router.push({
      //   name: 'Checkout'
      // })
    },
    async getCars(context, payload) {
      // console.log("payload",payload);
      let res = await carsApi.getCars(payload._id)
      if (res) {
        console.log(res.data.data);
        let data = {          
          ...payload,
          cars: [
            ...res.data.data
          ],
          time: new Date().getTime()
        };
        context.commit('SET_SHOP_PAGE_CARS', res.data.data);
        context.commit('SET_CARS', data);
      }
    },
  },
  getters: {
    makers: (state) => state.makers,
    // cars: (state) => state.cars,
    selectedCar: (state) => state.selectedCar,
    appLoading: (state) => state.appLoading,
    carListForShopPage: (state) => state.carListForShopPage,
    cars: (state) => _.unionBy(state.cars, '_id'),
  },
};
