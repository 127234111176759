import axios from "axios";
import router from "../router";
import Swal from "sweetalert2";
import { API_BASE_URL } from '../config';

const url = `${API_BASE_URL}/auth`;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response) {
      const { status } = error.response;
      console.log("status", status);
      if (status === 500) {
        Swal.fire({
          title: "Error!",
          text: errorMessage || "something went wrong. Re-login and try again",
          icon: "error",
          confirmButtonText: "Cool",
        });
        // localStorage.clear();
        // router.push({
        //   name: "Login",
        // });
      }
      const errorMessage = error.response.data.data.message;

      if (status !== 200) {
        Swal.fire({
          title: "Error!",
          text: errorMessage || "something went wrong",
          icon: "error",
          confirmButtonText: "Cool",
        });
      }
    }
  }
);

const login = async (email, password) => {
  try {
    return await axios.post(`${url}/login`, {
      email: email.toLowerCase(),
      password,
    });
  } catch (error) {
    console.error(error.response);
    // throw {
    //     code: error.response.status || 417,
    //     message: error.response.data.data.message || 'something went wrong please try again'
    // };
  }
};

const register = async (fullName, email, password, creditScore, finance) => {
  try {
    return await axios.post(`${url}/signup`, {
      fullName,
      email,
      password,
      creditScore,
      finance,
      type: "dealer",
    });
  } catch (error) {
    console.error(error.response);
    // throw {
    //     code: error.response.status || 417,
    //     message: error.response.data.data.message || 'something went wrong please try again'
    // };
  }
};

export default {
  login,
  register,
};
