import auth from '../api/auth';
import router from '@/router/index'



export const user = {
  state: () => ({
    user: {},
    loading: false,
    token: null
  }),
  mutations: {
    USER_LOGIN(state, payload) {
      state.user = payload;
      state.token = payload.token;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    }
  },
  actions: {
    async login(context, payload) {
      context.commit('SET_LOADING', true);
      console.log("payload",payload);
      let res = await auth.login(payload.email, payload.password)
      if (res) {
        console.log(res.data.data);
        let headers = { headers: { authtoken: `${res.data.data.token}` } }
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("headers", JSON.stringify(headers));
        context.commit('USER_LOGIN', res.data.data);
        context.commit('SET_LOADING', false);
        // setTimeout(() => {          
          if (localStorage.getItem("token")) {          
            router.push({
              name: 'Home'
            })
          }
        // }, 1000);
        // location.reload()
      }
      context.commit('SET_LOADING', false);
    },
    async register(context, payload) {
      console.log("payload",payload);
      let res = await auth.register(payload.fullName, payload.email, payload.password, payload.creditScore, payload.finance)
      if (res) {
        console.log(res.data.data);
        // localStorage.setItem("token", res.data.data.token);
        // context.commit('USER_LOGIN', res.data.data);
        router.push({
          name: 'Login'
        })
      }
    },
  },
  getters: {
    user: (state) => state.user,
    loading: (state) => state.loading,
    token: (state) => state.token
  },
};
