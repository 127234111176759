import axios from "axios";
import Swal from 'sweetalert2'
import { API_BASE_URL } from '../config';

const url = `${API_BASE_URL}`;

axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error && error.response) {
        const { status } = error.response;
        console.log("status", status);
        if (status === 500) {
          Swal.fire({
            title: "Error!",
            text: errorMessage || "something went wrong. Re-login and try again",
            icon: "error",
            confirmButtonText: "Cool",
          });
          // localStorage.clear();
          // router.push({
          //   name: "Login",
          // });
        }
        const errorMessage = error.response.data.data.message;
  
        if (status !== 200) {
          Swal.fire({
            title: "Error!",
            text: errorMessage || "something went wrong",
            icon: "error",
            confirmButtonText: "Cool",
          });
        }
      }
    }
  );


const getMakers = async () => {
    try {
        let localToken = localStorage.getItem('token')
        const token = { headers: { authtoken: `${localToken}` } }
        return await axios.get(`${url}/product/getCompanies`, token)
    } catch (error) {
        throw error
        console.error(error)
        // throw {
        //     code: error.response.status || 417,
        //     message: error.response.data.data.message || 'something went wrong please try again'
        // };
    }
}
const getCars = async (id) => {
    try {
        let localToken = localStorage.getItem('token')
        const token = { headers: { authtoken: `${localToken}` } }
        return await axios.get(`${url}/product/getCarsByCompanyId/${id}`, token)
    } catch (error) {
        console.error(error)
        // throw {
        //     code: error.response.status || 417,
        //     message: error.response.data.data.message || 'something went wrong please try again'
        // };
    }
}

const submitBid = async (data) => {
    try {
        let localToken = localStorage.getItem('token')
        const token = { headers: { authtoken: `${localToken}` } }
        let res = await axios.post(`${url}/bid/submit-bid`, data, token)
        if (res) {
            Swal.fire(
                'Your new car is submitted!',
                'Thank you for using NotSticker. Please wait patiently for your new car offers.',
                'success'
              )
        }
    } catch (error) {
        console.error(error)
        // throw {
        //     code: error.response.status || 417,
        //     message: error.response.data.data.message || 'something went wrong please try again'
        // };
    }
}


export default {
    getMakers,
    getCars,
    submitBid
}