import { createRouter, createWebHistory } from "vue-router";

const guardMyroute = (to, from, next) => {
  var isAuthenticated = false;
  //this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (localStorage.getItem("token")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
};
const checkIfLoggedIn = (to, from, next) => {
  var isAuthenticated = false;
  //this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (localStorage.getItem("token")) isAuthenticated = true;
  else isAuthenticated = false;
  console.log("isAuthenticated",isAuthenticated);
  if (isAuthenticated) {
    next('/'); // allow to enter route
  } else {
    next()
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "shop" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Home.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: checkIfLoggedIn,
    meta: {
      requiresLogin: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "shop" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Login.vue"
      ),
  },
  {
    path: "/register",
    name: "Register",
    beforeEnter: checkIfLoggedIn,
    meta: {
      requiresLogin: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "shop" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Register.vue"
      ),
  },
  {
    path: "/shop/:id",
    name: "ShopDefault",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "shop" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Shop.vue"
      ),
  },
  {
    path: "/allbids",
    name: "AllBids",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "shop" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/AllBids.vue"
      ),
  },
  {
    path: "/shop/:pageNo",
    name: "ShopWithPagination",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "shop" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Shop.vue"
      ),
  },
  {
    path: "/cart",
    name: "Cart",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "cart" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Cart.vue"
      ),
  },

  {
    path: "/products/:id",
    name: "Product",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "product" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Product.vue"
      ),
  },
  {
    path: "/checkout",
    name: "Checkout",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "checkout" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Checkout.vue"
      ),
  },
  {
    path: "/blog-list",
    name: "BlogList",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "blog-list" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/BlogList.vue"
      ),
  },
  {
    path: process.env.VUE_APP_BLOG_PATH + ":blogID",
    name: "BlogPost",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "blogpost" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/BlogPost.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/About.vue"
      ),
  },
  {
    path: "/my-account",
    name: "MyAccount",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "myaccount" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/MyAccount.vue"
      ),
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "wishlist" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Wishlist.vue"
      ),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Contact.vue"
      ),
  },
  {
    path: "/404",
    beforeEnter: guardMyroute,
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/NotFound.vue"
      ),
  },
  { path: "/:catchAll(.*)", redirect: "/404" },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.VUE_APP_BUILD_PATH),
  routes,
});

export default router;
